/** @jsx jsx */
/* eslint jsx-a11y/anchor-has-content: 0 */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import isAbsoluteURL from "is-absolute-url"

const styles = {
  display: "inline-flex",
  fontSize: [2, 3],
  borderRadius: "12px",
  padding: "16px 32px",
  textDecoration: "none",
  alignItems: "center",
  appearance: "none",
  border: "none",
  verticalAlign: "middle",
  variant: "buttons.primary",
}

export default ({ href, ...props }) => {
  const isExternal = isAbsoluteURL(href || "")
  if (isExternal) {
    return <a {...props} href={href} sx={styles} />
  }
  const to = props.to || href
  return <Link {...props} to={to} sx={styles} activeClassName="active" />
}
