const productStyles = {
  container: {
    backgroundColor: "#eee",
  },
  containerOl: {
    variant: "variants.container",
    listStyle: "none",
    mx: "auto",
    my: 0,
    py: 3,
    px: [3, 4, 3, 5],
    fontSize: 1,
    color: "#777",
    li: {
      display: "inline",
      mx: 2,
      "&:first-of-type": {
        ml: 0,
      },
    },
    a: {
      color: "#333",
      "&:hover": {
        color: "#000",
        textDecoration: "underline",
      },
    },
  },
  btnContainer: {
    display: "flex",
  },
  productContent: {
    overflow: "hidden",
    backgroundColor: "coverBackground",
    borderBottom: "1px solid #eee",
  },
  header: {
    bg: "#f9f9f9",
    position: ["relative", "relative", "sticky"],
    top: "0",
    zIndex: 999,
    boxShadow: "0 -5px 20px rgba(0,0,0, 0.3)",
  },
  headerContainer: {
    py: [3],
    variant: "variants.headerContainer",
    px: [3, 4, 4, 5],
    display: ["block", "block", "flex"],
    justifyContent: "space-between",
    gap: 2,
    alignItems: "center",
  },
  productContainer: {
    gridGap: [4, 5],
    variant: "variants.container",
    p: [3, 4, 4, 5],
    pb: "0 !important",
  },
  productBox: {
    pt: [3, 0],
    pb: [3, 3, 5],
  },
  productFeatured: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "2fr 1fr"],
    gap: 5,
    alignItems: ["start"],
  },
  contentGrid: {
    mb: 5,
    "h2 + .gatsby-image-wrapper": {
      mt: 4,
    },
    ".gatsby-image-wrapper": {
      borderRadius: "6px",
      overflow: "hidden",
      mb: 4,
    },
    h2: {
      fontSize: 3,
      mt: 0,
    },
  },
  productBoxDiv: {
    p: 0,
    ".gatsby-image-wrapper": {
      boxShadow: "0 -5px 20px rgba(0,0,0, 0.1)",
      borderRadius: "16px",
      overflow: "hidden",
    },
  },
  boxInfo: {
    display: "grid",
    alignContent: "space-between",
    maxWidth: "75ch",
    py: 2,
  },
  descText: {
    maxWidth: "75ch",
    color: "#0d1103",
    fontSize: [3, 3, 3, 4],
    pb: [3, 3, 0],
  },
  helpText: {
    fontSize: 1,
    color: "#757977",
  },
  boxSection: {
    pb: 4,
  },
  heading: {
    fontWeight: 700,
    fontSize: ["18px"],
    mb: ["3", "3", "0"]
  },
  priceText: {
    fontWeight: "600",
    fontSize: 0,
    ml: 3,
  },
  freeText: {
    fontWeight: "600",
    fontSize: 4,
    mb: 4,
  },
  demoLinkBtn: {
    variant: "buttons.secondary",
    fontSize: [2, 3],
    mr: 3,
    p: 3,
    px: 3,
    mb: 0,
    span: {
      fontSize: 1,
    },
    "&:hover": {
      borderColor: "#aaa",
      color: "#203999",
    },
  },
  buyLinkBtn: {
    variant: "buttons.secondary",
    display: "inline-flex !important",
    fontSize: [2, 3],
    alignItems: "center",
    appearance: "none",
    border: "none",
    verticalAlign: "middle",
    mr: 0,
    p: 3,
    px: 3,
    mb: 0,
    cursor: "pointer",
    span: {
      fontSize: 1,
    },
    backgroundColor: "#2c4cc8 !important",
    borderColor: "#2c4cc8 !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#203999",
      borderColor: "#203999",
      color: "#fff",
    },
    ".gumroad-button-logo": {
      display: "none !important"
    },
    "svg": {
      verticalAlign: "middle"
    }
  },
  netlifyLinkBtn: {
    variant: "buttons.secondary",
    mr: 0,
    p: 3,
    span: {
      fontSize: 2,
    },
  },
  githubLinkBtn: {
    variant: "buttons.secondary",
    fontSize: [2, 3],
    mr: 3,
    p: 3,
    px: 3,
    mb: 0,
    display: "flex",
    alignItems: "center",
    span: {
      fontSize: 1,
    },
    "&:hover": {
      borderColor: "#aaa",
      color: "#203999",
    },
  },
  buttonIcon: {
    mr: 3,
    fontSize: [2],
  },
  featuresContainer: {
    alignItems: "start",
    gridGap: 5,
    variant: "variants.container",
    px: [3, 4],
  },
  featuresContent: {
    p: 3,
    borderRadius: 12,
    border: "1px solid #eee",
    backgroundColor: "#f9f9f9",
    fontSize: 1,
    lineHeight: 1.5,
  },
  authorText: {
    mb: 1,
  },
  authorDetailsText: {
    pt: 3,
  },
  convertkit: {
    px: 4,
  },
}

export default productStyles
