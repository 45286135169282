/** @jsx jsx */
import { jsx, Grid } from "theme-ui"
import ProductCard from "../components/product-card"

const ProductsListConstructor = ({ data, showPosts }) => {
  const getProducts = data.edges
  const products = getProducts.slice(0, showPosts).map(edge => {
    const {
      id,
      slug,
      prefix,
      featuredImage,
      title,
      meta_description,
      productType,
      author,
    } = edge.node
    const path = prefix + slug
    const props = {
      id,
      slug,
      prefix,
      featuredImage,
      title,
      meta_description,
      productType,
      author,
      path,
    }

    return <ProductCard key={edge.node.id} data={props} />
  })
  return (
    <div sx={{ variant: "variants.container", pb: 5, bg: "#f9f9f9" }}>
      <h2>Related Jamstack Website Templates</h2>
      <Grid
        columns={[1, 2, 3]}
        sx={{
          gridGap: 4,
        }}
      >
        {products}
      </Grid>
    </div>
  )
}
export default ProductsListConstructor
