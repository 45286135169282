/** @jsx jsx */
import { jsx, Grid, Box } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import CodeBlock from "../components/code-block"
import { FaShoppingCart, FaExternalLinkAlt, FaGithub } from "react-icons/fa"
import { Icon } from "@iconify/react"
import netlifyIcon from "@iconify/icons-cib/netlify"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkButton from "../components/link-button"
import LatestProducts from "../components/latest-products"
import ScriptTag from 'react-script-tag'
import "../assets/scss/featured-banner.scss"
import productStyles from "../templates/product.styles"
import React from "react"

export const pageQuery = graphql`
  query productQuery($id: String!) {
    strapiProducts(id: { eq: $id }) {
      created_at
      updated_at
      slug
      prefix
      title
      content
      meta_description
      grids: Grids {
        URL
        title: Title
        desc: Description
        Image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      productType {
        githubURL
        price
        snipcartGUID
        affiliateURL
      }
      styling {
        title
        slug
      }
      liveDemo
      headlessCMS {
        title
        slug
      }
      ssg {
        title
        slug
      }
      author {
        title
        bio
        websiteURL
        slug
      }
      featuredImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twitterImage {
        publicURL
      }
    }
    latestProducts: allStrapiProducts(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { order: DESC, fields: updated_at }
    ) {
      totalCount
      edges {
        node {
          id
          created_at
          updated_at
          slug
          prefix
          title
          meta_description
          productType {
            githubURL
            snipcartGUID
            price
          }
          liveDemo
          author {
            title
            slug
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [416, 832, 1248, 1664]
              )
            }
          }
        }
      }
    }
  }
`

class Post extends React.Component {
  render () {
    const { strapiProducts, latestProducts } = this.props.data // data.markdownRemark holds your post data
    const {
      twitterImage,
      featuredImage,
      title,
      slug,
      meta_description,
      productType,
      affiliateURL = productType[0].affiliateURL,
      githubURL = productType[0].githubURL,
      price = productType[0].price,
      snipcartGUID = productType[0].snipcartGUID,
      styling,
      headlessCMS,
      ssg,
      author,
      content,
      grids,
      liveDemo
    } = strapiProducts
    const Image = featuredImage
      ? featuredImage.childImageSharp.gatsbyImageData
      : ""
    const smImage = twitterImage ? twitterImage.publicURL : ""

    const productPrice = price > 0 ? "$" + price : ""
    const demoLink = liveDemo

    const contentGrids = grids.map((grid, index) => {
      const contentImage = grid.Image
        ? grid.Image.childImageSharp.gatsbyImageData
        : ""
      return (
        <div key={index} sx={productStyles.contentGrid}>
          {grid.title && <h2>{grid.title}</h2>}
          {grid.URL ? (
            <a href={grid.URL} target="_black">
              {contentImage && (
                <GatsbyImage
                  image={contentImage}
                  alt={title + "- feature " + index}
                />
              )}
            </a>
          ) : (
            contentImage && (
              <GatsbyImage
                image={contentImage}
                alt={title + "- feature " + index}
              />
            )
          )}
          {grid.desc && (
            <ReactMarkdown source={grid.desc} renderers={{ code: CodeBlock }} />
          )}
        </div>
      )
    })

    return (
      <Layout>
        <SEO
          title={title + " • Stackrole"}
          description={meta_description}
          image={smImage}
          article={true}
        />
        <ScriptTag isHydrating={true} type="text/javascript" src="https://gumroad.com/js/gumroad.js" />
        {/* BreadCrump: To be a component */}
        <article className="product-content">
          <header sx={productStyles.header}>
            <div sx={productStyles.headerContainer}>
              <div>
                <h1 sx={productStyles.heading}>{title}</h1>
              </div>
              <div sx={productStyles.btnContainer}>
                <LinkButton
                  to={demoLink}
                  target="_blank"
                  rel="noreferrer"
                  sx={productStyles.demoLinkBtn}
                  title="View demo"
                >
                  <FaExternalLinkAlt sx={productStyles.buttonIcon} />{" "}
                  <span>Live Preview</span>
                </LinkButton>
                {affiliateURL && (
                  <OutboundLink
                    className="gumroad-button"
                    href={affiliateURL + "?wanted=true"}
                    sx={productStyles.buyLinkBtn}
                    title={"Buy " + title}
                  >
                    <FaShoppingCart sx={productStyles.buttonIcon} />{" "}
                    <span>Buy Now</span>
                    <span sx={productStyles.priceText}>{productPrice}</span>
                  </OutboundLink>
                )}
                {!snipcartGUID && !affiliateURL && (
                  <OutboundLink
                    href={githubURL}
                    target="_blank"
                    rel="noreferrer"
                    sx={productStyles.githubLinkBtn}
                    title="Source on Github"
                  >
                    <FaGithub sx={productStyles.buttonIcon} />
                    <span>Github</span>
                  </OutboundLink>
                )}
                {!snipcartGUID && !affiliateURL && (
                  <OutboundLink
                    href={
                      "https://app.netlify.com/start/deploy?repository=" +
                      githubURL
                    }
                    target="_blank"
                    rel="noreferrer"
                    sx={productStyles.buyLinkBtn}
                    title="Deploy to Netlify"
                  >
                    <Icon sx={productStyles.buttonIcon} icon={netlifyIcon} />
                    <span>Deploy</span>
                  </OutboundLink>
                )}
              </div>
            </div>
          </header>
          <section sx={productStyles.productContent}>
            <Grid columns={["1fr"]} sx={productStyles.productContainer}>
              <Box sx={productStyles.productBox}>
                <div sx={productStyles.productFeatured}>
                  <div sx={productStyles.productBoxDiv}>
                    <Link to={demoLink}>
                      {Image && (
                        <GatsbyImage image={Image} alt={title + "- cover"} />
                      )}
                    </Link>
                  </div>
                  <div>
                    <p sx={productStyles.descText}>{meta_description}</p>
                    <p sx={productStyles.authorDetailsText}>
                      This Jamstack website template is built with
                    </p>
                    <p>
                      <strong>Static Site Generator</strong>
                      <br />
                      <Link to={`/ssg/${ssg.slug}/`}>{ssg.title}</Link>
                    </p>
                    <p>
                      <strong>Headless CMS</strong>
                      <br />
                      <Link to={`/cms/${headlessCMS.slug}/`}>
                        {headlessCMS.title}
                      </Link>
                    </p>
                    <p>
                      <strong>Styling</strong>
                      <br />
                      <Link to={`/styling/${styling.slug}/`}>
                        {styling.title}
                      </Link>
                    </p>
                  </div>
                </div>
              </Box>
            </Grid>
          </section>
          <Grid
            columns={["1fr", "1fr", "minmax(404px, auto) 300px"]}
            sx={productStyles.featuresContainer}
          >
            <div className="blog-post-content -product">
              {grids && contentGrids}
              <ReactMarkdown source={content} renderers={{ code: CodeBlock }} />
            </div>
            <div sx={productStyles.featuresContent}>
              <h3>
                By
                <Link to={"/author/" + author.slug + "/"}>
                  {" "}
                  <strong>{author.title}</strong>{" "}
                </Link>
              </h3>
              <p sx={productStyles.authorText}>{author.bio}</p>
              <a href={author.websiteURL} target="_blank" rel="noreferrer">
                Know more &raquo;
              </a>
            </div>
          </Grid>
          <Box variant="variants.container"></Box>
        </article>
        <LatestProducts data={latestProducts} />
        <div sx={productStyles.container}>
          <ol
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            sx={productStyles.containerOl}
          >
            <li
              itemScope
              itemProp="itemListElement"
              itemType="https://schema.org/ListItem"
            >
              <Link itemProp="item" to="/jamstack/">
                <span itemProp="name">Jamstack Marketplace</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            ›
            <li itemProp="itemListElement" itemType="https://schema.org/ListItem">
              <Link
                itemScope
                itemType="https://schema.org/WebPage"
                itemProp="item"
                itemID={"/jamstack/" + slug + "/"}
                to={"/jamstack/" + slug + "/"}
              >
                <span itemProp="name">{title}</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            ›
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <span itemProp="name">Info</span>
              <meta itemProp="position" content="3" />
            </li>
          </ol>
        </div>
      </Layout>
    )
  }
}

export default Post
